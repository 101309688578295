/**
 * @fileoverview Polyfills needed by Angular and loaded before the app.
 * You can add your own extra polyfills to this file.
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
import 'core-js/es6/symbol';
import 'core-js/es6/object';
import 'core-js/es6/function';
import 'core-js/es6/parse-int';
import 'core-js/es6/parse-float';
import 'core-js/es6/number';
import 'core-js/es6/math';
import 'core-js/es6/string';
import 'core-js/es6/date';
import 'core-js/es6/array';
import 'core-js/es6/regexp';
import 'core-js/es6/map';
import 'core-js/es6/weak-map';
import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following to support `@angular/animation`. */
import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/** Evergreen browsers require these. **/
import 'core-js/es6/reflect';

/** ALL Firefox browsers require the following to support `@angular/animation`. **/
import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/* ngx-charts required polyfill ie11 */
import 'core-js/es7/array';

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/**
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';

/**
 * The require function to dynamically load modules.
 * @type {any}
 */
declare var require: any;

/**
 * Assign global variable for window.
 * @global
 */
(window as any).global = window;

/**
 * Extend the global Window interface to include process and require.
 * @interface Window
 */
declare global {
  interface Window {
    /**
     * Process object for polyfilling process functionality.
     * @type {any}
     */
    process: any;

    /**
     * Require function for loading modules.
     * @type {any}
     */
    require: any;
  }
}

/**
 * Polyfill for Buffer to support legacy modules.
 * @global
 * @type {Buffer}
 */
(window as any).global.Buffer = (window as any).global.Buffer || require('buffer').Buffer;
